import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../../redux/actions'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import LinkIcon from '@material-ui/icons/Link'
import getGatewaysResourceUrl from '../../../../../lib/getGatewaysResourceUrl'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import { isEnvironmentAdmin } from '../../../../../lib/user-environment-permissions-helpers'
import * as validateData from '../../../../../lib/validate-gateways-data'
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme"
import CustomLink from '../../../../Widgets/CustomLink'
import Header from '../../../../Widgets/Editor/Header'
import type {
    TState as TGatewayEditState,
    TOnSetState as TOnSetParentState,
} from '../'
import ActionButtons from './ActionButtons'

type TProps = {
    parentState: TGatewayEditState
    accounts: any
    config: any
    location: any
    login: any
    muiTheme: any
    gatewayClearCache: Function
    generalMessageSet: Function
    getQueueName: () => string
    initialize: () => void
    // eslint-disable-next-line no-unused-vars
    onSetParentState: TOnSetParentState<TGatewayEditState>
}

type TState = {
    showClearingSnackBar: boolean
    showClientSecret: boolean
}

class Details extends React.Component<TProps, TState> {
    public constructor(props: TProps) {
        super(props)
        this.state = {
            showClearingSnackBar: false,
            showClientSecret: false,
        }
    }


    public componentDidMount() {
        this.validateData()
    }

    public componentDidUpdate(prevProps: TProps) {
        if (JSON.stringify(this.props.parentState.data) !== JSON.stringify(prevProps.parentState.data)) {
            this.validateData()
        }
    }

    public render() {
        const { accountId } = getHostnameInfo()
        const { env, gatewayId } = getLocationInfo(this.props.location, this.props.config)

        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config)
        const canEdit = this.props.parentState.mode === ":EDIT:"
        const isValid = Object.keys(this.props.parentState.dataValidation).reduce((acc, key) => acc && !this.props.parentState.dataValidation[key], true)

        return (
            <Card style={{ marginTop: '48px' }}>
                <Header
                    leftIcon={<LinkIcon style={{ padding: "12px" }}/>}
                    style={{
                        display: "flex",
                        height: "56px",
                        alignItems: "space-between",
                    }}
                    primaryText={(
                        <div style={{ display: "flex", flex: "5" }}>
                            <div style={{
                                padding: "4px",
                                fontSize: "14px",
                                color: this.props.muiTheme.palette.secondaryTextColor,
                            }}>
                                {this.props.parentState.data.gatewayType === "HL7_V2"
                                    ? "hl7://interopiondemo.smilecdr.com:7000"
                                    : getGatewaysResourceUrl(accountId, env, gatewayId, this.props.config.proxyApi)}
                            </div>
                            <div>
                                <Tooltip title="Copy" placement="bottom">
                                    <IconButton
                                        style={{
                                            width: "24px",
                                            height: "24px",
                                            padding: 0,
                                            marginLeft: "5px",
                                        }}
                                        onClick={this.handleCopy}
                                    >
                                        <FileCopyIcon style={{ color: this.props.muiTheme.palette.secondaryTextColor }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    rightIcon={!canEdit && actAsAdmin
                        ? <Tooltip title="Edit">
                            <IconButton
                                data-qa="gateway-edit-button"
                                style={{ paddingTop: '18px' }}
                                onClick={() => {
                                    this.props.onSetParentState((prevState) => ({
                                        ...prevState,
                                        mode: ':EDIT:',
                                    }))
                                }}
                            >
                                <EditIcon style={{ fill: this.props.muiTheme.palette.secondaryTextColor }}/>
                            </IconButton>
                        </Tooltip>
                        : null}
                />

                <div style={{padding: "24px"}}>
                    {this.renderName(canEdit)}
                    {this.renderGatewayId()}
                    {this.renderDescription(canEdit)}
                    {this.renderConfigCache(canEdit, actAsAdmin, accountId, env, gatewayId)}
                    {this.renderHL7Messages(canEdit)}
                    {this.renderType(canEdit)}
                    {this.props.parentState.data.gatewayType !== "HL7_V2"
                        ? this.renderAuthenticationType(canEdit)
                        : null
                    }
                    {this.props.parentState.data.authenticationType === "OKTA_JWK"
                        ? this.renderOktaJwkUrl(canEdit)
                        : null
                    }
                    <br/>
                    {canEdit ? <br/> : null}
                    {this.props.parentState.data.gatewayType !== "HL7_V2"
                        ? this.renderUserDir(canEdit)
                        : null
                    }
                    {this.props.parentState.data.gatewayType === "HL7_V2"
                        ? this.renderPort(canEdit)
                        : null
                    }
                    {this.props.parentState.data.hl7Enabled
                        ? this.renderHl7MessageTypes(canEdit)
                        : null
                    }
                    {this.props.parentState.data.hl7Enabled
                        ? this.renderHl7QueueName()
                        : null
                    }
                    {this.props.parentState.data.hl7Enabled
                        ? this.renderHl7Url(canEdit)
                        : null
                    }
                    {this.props.parentState.data.hl7Enabled
                        ? this.renderHl7UrlAuthType(canEdit)
                        : null
                    }
                    {
                        this.props.parentState.data.hl7Enabled
                        && this.props.parentState.data.hl7Properties?.urlAuthType === 'CLIENT_CREDENTIALS'
                            ? this.renderHl7ClientId(canEdit)
                            : null
                    }
                    {
                        this.props.parentState.data.hl7Enabled
                        && this.props.parentState.data.hl7Properties?.urlAuthType === 'CLIENT_CREDENTIALS'
                            ? this.renderHl7ClientSecret(canEdit)
                            : null
                    }
                    {
                        this.props.parentState.data.hl7Enabled
                        && this.props.parentState.data.hl7Properties?.urlAuthType === 'CLIENT_CREDENTIALS'
                            ? this.renderHl7ClientAuthUrl(canEdit)
                            : null
                    }

                    <Snackbar
                        open={this.state.showClearingSnackBar}
                        message="Clearing cache"
                    />
                </div>

                <ActionButtons
                    isValid={isValid}
                    parentState={this.props.parentState}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                    getQueueName={this.props.getQueueName}
                    initialize={this.props.initialize}
                    onSetParentState={this.props.onSetParentState}
                />
            </Card>
        )
    }

    private renderName = (canEdit) => {
        const props = {
            inputProps: { 'data-qa': 'gateway-name-input'},
            name: 'name',
            value: this.props.parentState.data.name || '',
            error: !!this.props.parentState.dataValidation.name,
            label: 'Display Name',
            helperText: this.props.parentState.dataValidation.name,
            fullWidth: true,
        };
        const additionalProps = canEdit
            ? {
                hint: 'Type a name for the gateway',
                onChange: (event) => {
                    this.props.onSetParentState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            name: event.target.value,
                        },
                    }))
                },
            }
            : {
                disabled: true,
                classes: { root: 'disabled-text-field' },
                style: {
                    cursor: 'default',
                    color: this.props.muiTheme.palette.textColor,
                },
            }

        return <TextField {...props} {...additionalProps} />
    }

    private renderGatewayId = () => {
        return (
            <TextField
                name="gatewayId"
                value={this.props.parentState.data.gatewayId || ''}
                disabled
                label="Gateway ID"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{
                    marginTop: '18px',
                    cursor: 'default',
                }}
            />
        )
    }

    private renderDescription = (canEdit) => {
        const props = {
            inputProps: { 'data-qa': 'gateway-description-input'},
            name: 'description',
            value: this.props.parentState.data.description || '',
            label: 'Description',
            fullWidth: true,
            multiline: true,
            style: { marginTop: '18px' },
        }
        const additionalProps = canEdit
            ? {
                onChange: (event) => {
                    this.props.onSetParentState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            description: event.target.value,
                        },
                    }))
                }
            }
            : {
                disabled: true,
                classes: { root: 'disabled-text-field' },
                style: {
                    ...props.style,
                    cursor: 'default',
                    color: this.props.muiTheme.palette.textColor,
                }
            }

        return <TextField {...props} {...additionalProps} />
    }

    private renderConfigCache = (canEdit, actAsAdmin, accountId, environmentId, gatewayId) => {
        return canEdit
            ? (
                <FormControlLabel
                    label={`Caching ${this.props.parentState.data.configCacheMinutes ? 'Enabled' : 'Disabled'}`}
                    style={{
                        width: '100%',
                        marginTop: '28px',
                        marginBottom: '20px',
                    }}
                    control={(
                        <Switch
                            data-qa="gateway-caching-toggle"
                            checked={!!this.props.parentState.data.configCacheMinutes}
                            color="primary"
                            onChange={(event) => {
                                this.props.onSetParentState((prevState) => ({
                                    ...prevState,
                                    data: {
                                        ...prevState.data,
                                        configCacheMinutes: event.target.checked ? 1 : 0
                                    },
                                }))
                            }}
                        />
                    )}/>
            )
            : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        marginTop: '18px',
                    }}
                >
                    <TextField
                        inputProps={{ 'data-qa': 'gateway-caching-info' }}
                        name="gateway-cache"
                        value={`Caching ${this.props.parentState.data.configCacheMinutes ? 'Enabled' : 'Disabled'}`}
                        disabled
                        label="Cache"
                        classes={{ root: 'disabled-text-field' }}
                        style={{
                            width: '140px',
                            color: this.props.muiTheme.palette.textColor,
                            cursor: 'default',
                        }}
                    />
                    {actAsAdmin && this.props.parentState.data.configCacheMinutes ? (
                        <Tooltip title="Clear cache">
                            <IconButton onClick={() => {
                                this.setState({ showClearingSnackBar: true }, async () => {
                                    await this.props.gatewayClearCache(accountId, environmentId, gatewayId)
                                    this.setState({ showClearingSnackBar: false })
                                })
                            }}>
                                <AutorenewIcon style={{color: this.props.muiTheme.palette.secondaryTextColor}} />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </div>
            )
    }

    private renderHL7Messages = (canEdit) => {
        const isGatewayTypeR4 = this.props.parentState.data.gatewayType === 'FHIR_R4__4_0_1'
        // const subscription = (this.props.accounts.selected?.data?.interopio_subscription || "").toLowerCase()
        const tier = (this.props.accounts.selected?.data?.tier || '').toLowerCase()
        const tierNum = Number(tier.split(' ')[1])
        const isTier3Plus = tierNum >= 3
        // const isHl7MessagesEnabled = isGatewayTypeR4 && (isTier3Plus || subscription.includes("team developer"))
        const isHl7MessagesEnabled = isGatewayTypeR4 && isTier3Plus
        return canEdit
            ? (
                <FormControlLabel
                    label="Enable Incoming HL7 V2 Messages"
                    disabled={!isHl7MessagesEnabled}
                    control={
                        <Switch
                            data-qa="gateway-hl7-toggle"
                            checked={!!this.props.parentState.data.hl7Enabled}
                            color="primary"
                            onChange={(event) =>
                                this.props.onSetParentState((prevState) => ({
                                    ...prevState,
                                    data: {
                                        ...prevState.data,
                                        hl7Enabled: event.target.checked,
                                    },
                                }))
                            }
                        />
                    }
                />
            ) : (
                <TextField
                    inputProps={{ 'data-qa': 'gateway-hl7' }}
                    name="gateway-hl7"
                    value={`${this.props.parentState.data.hl7Enabled ? 'Enabled' : 'Disabled'}`}
                    label="HL7 V2 Messages"
                    fullWidth
                    disabled
                    classes={{ root: 'disabled-text-field' }}
                    style={{ marginTop: '18px' }}
                />
            )
    }

    private renderType = (canEdit) => {
        const gatewayType = this.props.parentState?.data?.gatewayType || ''
        let value = ''
        switch (gatewayType) {
            case 'FHIR_DSTU2__1_0_2': value = 'FHIR DSTU2 v1.0.2'; break
            case 'FHIR_STU3__3_0_2': value = 'FHIR STU3 v3.0.2'; break
            case 'FHIR_R4__4_0_1': value = 'FHIR R4 v4.0.1'; break
            case 'HL7_V2': value = 'HL7 v2.x Listening Endpoint'; break
            default: value = 'Unknown'
        }

        return (
            <TextField
                name="gatewayType"
                value={value}
                disabled
                label="Gateway Standard"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{
                    marginTop: '18px',
                    color: !canEdit ? this.props.muiTheme.palette.textColor : undefined,
                    cursor: 'default',
                }}
            />
        )
    }

    private renderAuthenticationType = (canEdit) => {
        const gatewayType = this.props.parentState?.data?.configuration?.type || ''
        const gatewayAuthenticationTypeOptions = this.props.parentState?.data?.gatewayAuthenticationTypeOptions || ['NONE']
        return (canEdit)
            ? (
                <FormControl
                    fullWidth
                    style={{ marginTop: '16px' }}
                >
                    <InputLabel>
                        Authentication Type
                    </InputLabel>
                    <Select
                        value={this.props.parentState.data.authenticationType}
                        disabled={!gatewayType || gatewayType === 'GatewayConfigurationDefault' || gatewayAuthenticationTypeOptions.length == 1}
                        label="Authentication Type"
                        autoWidth
                        style={{ marginTop: '18px' }}
                        onChange={(event) => this.props.onSetParentState((prevState) => ({
                            ...prevState,
                            data: {
                                ...prevState.data,
                                authenticationType: event.target.value as TProps['parentState']['data']['authenticationType'],
                            },
                        }))}
                    >
                        {gatewayAuthenticationTypeOptions.map((authType) => {
                            return (
                                <MenuItem
                                    key={authType}
                                    value={authType}
                                >
                                    {authType.replace(/_/g, " ")}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            ) : (() => {
                let value = ''
                switch (this.props.parentState.data.authenticationType) {
                    case 'NONE': value = 'NONE'; break
                    case 'SMART_ON_FHIR':value = 'SMART on FHIR'; break
                    case 'OKTA_JWK':value = 'OKTA JWK'; break
                    case 'JWK_ASSERTION': value = 'JWK ASSERTION'; break
                    case 'G2': value = 'G2'; break
                    default: value = 'Unknown'
                }
                return (
                    <TextField
                        name="authenticationType"
                        value={value}
                        disabled
                        label="Authentication Type"
                        fullWidth
                        classes={{ root: 'disabled-text-field' }}
                        style={{
                            marginTop: '18px',
                            paddingBottom: '6px',
                            color: this.props.muiTheme.palette.textColor,
                            cursor: 'default',
                        }}
                    />
                )
            })()
    }

    private renderOktaJwkUrl = (canEdit) => {
        const props = {
            inputProps: { 'data-qa': 'gateway-okta-jwk-url-input'},
            name: 'oktaJwkUrl',
            value: this.props.parentState.data.oktaJwkUrl || '',
            error: !!this.props.parentState.dataValidation.oktaJwkUrl,
            label: 'Okta JWK URL',
            helperText: this.props.parentState.dataValidation.oktaJwkUrl,
            fullWidth: true,
            style: { marginTop: '18px' },
        };
        const additionalProps = canEdit
            ? {
                placeholder: 'Enter Okta JWK URL',
                onChange: (event) => {
                    this.props.onSetParentState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            oktaJwkUrl: event.target.value,
                        },
                    }))
                },
            }
            : {
                disabled: true,
                classes: { root: 'disabled-text-field' },
                style: {
                    ...props.style,
                    cursor: 'default',
                    color: this.props.muiTheme.palette.textColor,
                },
            }

        return <TextField {...props} {...additionalProps} />
    }

    private renderUserDir = (canEdit) => {
        const defaultSourceUserStrategy = this.props.parentState?.data?.userStrategy || ''
        const directoryId = this.props.parentState?.data?.userDirectory?.directoryId

        return (canEdit) ? (() => {
            if (defaultSourceUserStrategy === 'DELEGATED') {
                return (
                    <FormControl fullWidth style={{ marginTop: '16px' }}>
                        <InputLabel>
                            User Directory
                        </InputLabel>
                        <Select
                            data-qa='new-gateway-user-dir-select'
                            value='DELEGATED'
                            label='User Directory'
                            disabled
                            autoWidth
                            style={{ marginTop: '16px' }}
                        >
                            <MenuItem
                                data-qa='new-gateway-user-dir-delegated'
                                value='DELEGATED'
                            >
                                Delegated
                            </MenuItem>
                        </Select>
                    </FormControl>
                )
            }

            const gatewayType = this.props.parentState?.data?.configuration?.type || ''
            const userDirsIo = this.props.parentState?.userDirsSlim
                .filter((ud) => ud.directoryType === 'INTEROPIO_USERS')

            return (
                <FormControl fullWidth style={{ marginTop: '16px' }}>
                    <InputLabel>
                        User Directory
                    </InputLabel>
                    <Select
                        data-qa='new-gateway-user-dir-select'
                        value={directoryId}
                        label='User Directory'
                        disabled={!gatewayType || gatewayType === 'GatewayConfigurationDefault'}
                        autoWidth
                        MenuProps={{ style: { zIndex: 10000 } }}
                        style={{ marginTop: '16px' }}
                        onChange={(event) => {
                            this.props.onSetParentState((prevState) => ({
                                ...prevState,
                                data: {
                                    ...prevState.data,
                                    userDirectory: {
                                        ...prevState.data.userDirectory,
                                        directoryId: event.target.value as string,
                                    },
                                },
                            }))
                        }}
                    >
                        <MenuItem
                            data-qa='new-gateway-user-dir-none'
                            value={'NONE'}
                        >
                            NONE
                        </MenuItem>
                        {userDirsIo.map((item, index) => {
                            const directoryId = item.directoryId || ''
                            const directoryName = item.name || ''
                            return (
                                <MenuItem
                                    key={`${directoryId}-${index}`}
                                    data-qa={`new-gateway-user-dir-${item.directoryId}`}
                                    value={directoryId}
                                >
                                    {directoryName}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )
        })() : (() => {
            const userDirsSlim = this.props.parentState.userDirsSlim
            const directoryObj = userDirsSlim.find((ud) => ud.directoryId === directoryId)

            let directoryName = directoryObj?.name || 'NONE'
            if (defaultSourceUserStrategy === 'DELEGATED') {
                directoryName = 'Delegated'
            }

            const { environmentId } = this.props.parentState.data

            return (
                directoryName === 'Delegated' || directoryName === 'NONE'
                    ? (
                        <TextField
                            name='userDir'
                            value={directoryName}
                            label='User Directory'
                            data-e2e='gw_usr_dir_delegated'
                            fullWidth
                            disabled
                            classes={{ root: 'disabled-text-field' }}
                            style={{ paddingBottom: '6px', cursor: 'default', marginTop: '16px' }}
                        />
                    ) : (
                        <div>
                            <div className='portal-gateway-link-title'>User Directory</div>
                            <CustomLink to={`/${environmentId}/auth/${directoryId}`}>
                                <span data-e2e={`gw_usr_dir_${directoryName}`}>
                                    {directoryName}
                                </span>
                            </CustomLink>
                        </div>
                    )
            )
        })()
    }

    private renderPort = (canEdit) => {
        return (
            <TextField
                name="port"
                value="7000"
                disabled
                label="Port"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{
                    marginTop: '18px',
                    color: !canEdit ? this.props.muiTheme.palette.textColor : undefined,
                    cursor: 'default',
                }}
            />
        )
    }

    private renderHl7MessageTypes = (canEdit) => {
        return canEdit ? (
            <TextField
                value={this.props.parentState.data.hl7Properties?.messageTypes || ''}
                placeholder="Message types"
                label="Message Types"
                fullWidth
                style={{ marginTop: '18px' }}
                onChange={(event) => this.props.onSetParentState((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        hl7Properties: {
                            ...prevState.data.hl7Properties,
                            messageTypes: event.target.value,
                        },
                    },
                }))}
            />
        ) : (
            <TextField
                value={this.props.parentState.data.hl7Properties?.messageTypes || ''}
                label="Message Types"
                fullWidth
                disabled
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />
        )
    }

    private renderHl7QueueName = () => {
        return (
            <TextField
                value={this.props.getQueueName()}
                disabled
                label="Queue Name"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{
                    marginTop: '18px',
                    cursor: 'default',
                }}
            />
        )
    }

    private renderHl7Url = (canEdit) => {
        return canEdit ? (
            <TextField
                value={this.props.parentState.data.hl7Properties?.url || ''}
                placeholder="URL"
                label="URL"
                helperText={this.props.parentState.dataValidation.hl7Url}
                error={!!this.props.parentState.dataValidation.hl7Url}
                fullWidth
                style={{ marginTop: '18px' }}
                onChange={(event) => {
                    this.props.onSetParentState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            hl7Properties: {
                                ...prevState.data.hl7Properties,
                                url: event.target.value,
                            },
                        },
                    }))
                }}
            />
        ) : (
            <TextField
                value={this.props.parentState.data.hl7Properties?.url || ''}
                disabled
                label="URL"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />
        )
    }

    private renderHl7UrlAuthType = (canEdit) => {
        return canEdit ?(
            <FormControl fullWidth style={{ marginTop: '18px' }}>
                <InputLabel>
                    Authentication Type
                </InputLabel>
                <Select
                    value={this.props.parentState.data.hl7Properties?.urlAuthType}
                    autoWidth
                    MenuProps={{ style: { zIndex: 10000 } }}
                    onChange={(event) => {
                        this.props.onSetParentState((prevState) => ({
                            ...prevState,
                            data: {
                                ...prevState.data,
                                hl7Properties: {
                                    ...prevState.data.hl7Properties,
                                    urlAuthType: event.target.value as TProps['parentState']['data']['hl7Properties']['urlAuthType'],
                                },
                            },
                        }))
                    }}
                >
                    <MenuItem value="NONE">
                        NONE
                    </MenuItem>
                    <MenuItem value="CLIENT_CREDENTIALS">
                        CLIENT CREDENTIALS
                    </MenuItem>
                </Select>
            </FormControl>
        ) :
            (() => {
                let value = ''
                switch (this.props.parentState.data.hl7Properties?.urlAuthType) {
                    case 'NONE': value = 'NONE'; break
                    case 'CLIENT_CREDENTIALS': value = 'CLIENT CREDENTIALS'; break
                    default: value = 'Unknown'; break
                }
                return (
                    <TextField
                        value={value}
                        disabled
                        label="Authentication Type"
                        fullWidth
                        classes={{ root: 'disabled-text-field' }}
                        style={{ marginTop: '18px' }}
                    />
                )
            })()
    }

    private renderHl7ClientId = (canEdit) => {
        return canEdit ? (
            <TextField
                value={this.props.parentState.data.hl7Properties?.clientId || ''}
                placeholder="Client ID"
                label="Client ID"
                fullWidth
                style={{ marginTop: '18px' }}
                onChange={(event) => {
                    this.props.onSetParentState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            hl7Properties: {
                                ...prevState.data.hl7Properties,
                                clientId: event.target.value,
                            },
                        },
                    }))
                }}
            />
        ) : (
            <TextField
                value={this.props.parentState.data.hl7Properties?.clientId || ''}
                disabled
                label="Client ID"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />
        )
    }

    private renderHl7ClientSecret = (canEdit) => {
        return canEdit ? (
            <>
                <FormControl style={{
                    display: 'block',
                    marginTop: '18px',
                }}>
                    <InputLabel>
                        Client Secret
                    </InputLabel>
                    <Input
                        value={this.props.parentState.data.hl7Properties?.clientSecret || ''}
                        placeholder="Client secret"
                        type={this.state.showClientSecret ? 'text' : 'password'}
                        fullWidth

                        onClick={canEdit ? (e => {
                            let targetValue = (e.target as any).value;
                            if (targetValue.localeCompare("<hidden>") === 0 || !(targetValue)) {
                                this.props.onSetParentState((prevState) => ({
                                    ...prevState,
                                    data: {
                                        ...prevState.data,
                                        hl7Properties: {
                                            ...prevState.data.hl7Properties,
                                            clientSecret: "",
                                        },
                                    },
                                }))
                            }
                            this.setState({ showClientSecret: true });
                        }) : null}

                        onChange={(event) => {
                            this.props.onSetParentState((prevState) => ({
                                ...prevState,
                                data: {
                                    ...prevState.data,
                                    hl7Properties: {
                                        ...prevState.data.hl7Properties,
                                        clientSecret: event.target.value,
                                    },
                                },
                            }))
                        }}
                    />
                </FormControl>
            </>
        ) :
            (
                <TextField
                    value={this.props.parentState.data.hl7Properties?.clientSecret || ''}
                    disabled
                    label="Client Secret"
                    fullWidth
                    type="password"
                    classes={{ root: 'disabled-text-field' }}
                    style={{ marginTop: '18px' }}
                />
            )
    }

    private renderHl7ClientAuthUrl = (canEdit) => {
        return canEdit ? (
            <TextField
                value={this.props.parentState.data.hl7Properties?.clientAuthUrl || ''}
                placeholder="Client auth URL"
                label="Client Auth URL"
                fullWidth
                style={{ marginTop: '18px' }}
                onChange={(event) => {
                    this.props.onSetParentState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            hl7Properties: {
                                ...prevState.data.hl7Properties,
                                clientAuthUrl: event.target.value,
                            },
                        },
                    }))
                }}
            />
        ) : (
            <TextField
                value={this.props.parentState.data.hl7Properties?.clientAuthUrl || ''}
                disabled
                label="Client Auth URL"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />
        )
    }

    private handleCopy = () => {
        const { accountId } = getHostnameInfo()
        const { env, gatewayId } = getLocationInfo(this.props.location, this.props.config)
        const src = this.props.parentState.data.gatewayType === "HL7_V2"
            ? "hl7://interopiondemo.smilecdr.com:7000"
            : getGatewaysResourceUrl(accountId, env, gatewayId, this.props.config.proxyApi)
        const sanitizedSrc = encodeURI(src);
        const container = document.createElement('textarea')
        container.innerHTML = sanitizedSrc
        document.body.appendChild(container)
        container.select()
        document.execCommand('copy')
        document.body.removeChild(container)
        this.props.generalMessageSet({
            type: ':INFO_SNACKBAR:',
            title: 'Copied to Clipboard',
        })
    }

    private validateData = () => {
        const dataValidation = validateData.onEditDetails(this.props.parentState.data)
        this.props.onSetParentState((prevState) => ({
            ...prevState,
            dataValidation,
        }))
    }
}

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    config: state.config,
    login: state.login,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    gatewayClearCache: actions.gatewayClearCache,
    generalMessageSet: actions.generalMessageSet,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Details)))
